@use '@angular/material' as mat;
@import 'theme';
@import 'variables.scss';

body {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: mat.get-color-from-palette($app-theme, 300);

  &:focus {
    text-decoration: underline;
  }
}

@mixin page-title {
  font-size: 24px;
  font-weight: 700;
}

@mixin font-McLaren {
  font-family: 'McLaren', cursive;
}

.feeding-survey .formItem-label {
  display: block;

  margin-top: 1em;

  font-size: 18px;
}
