$green: #7BA946;
$light-green: #93C453;
$dark-green: #5C7A36;
$panache-green: #eaf6ea;
$fern-green: #5cb85c;
$bright-blue: #33b2cc;
$blue: #4274BC;
$blue-light: #559BE0;
$blue-dark: #346391;
$astral-blue: #337ab7;
$mariner-blue: #3373c2;
$red: #DB2C2C;
$light-red: #E08585;
$dawn-pink: #f6eaeb;
$matrix-red: #b85c5c;
$purple: #945CC6;
$black: #333;
$orange: #D8963F;
$pink: #DD57B7;
$yellow: #E0C62D;
$silver-grey: #cccccc;
$grey: #eee;
$light-neutral-grey: #afafaf;
$neutral-gray: #7f7f7f;
$disabled-grey: #b0b0b0;
$dove-grey: #666666;
$dark: #000;
$white: #ffffff;

$mobile-width: 425px;
$tablet-width: 768px;
$desktop: 1024px;
