@import './variables';

@mixin flexer($display: flex, $direction: row, $content: flex-start, $items: stretch) {
  display: $display;
  flex-direction: $direction;
  justify-content: $content;
  align-items: $items;
}

@mixin btn-green {
  padding: 7px 20px 9px 20px;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  transition: border 0.3s ease, color 0.3s ease;
  cursor: pointer !important;
  border-color: $green !important;
  color: $green !important;
  background-color: #fff;
  margin: 0;
  outline: none;
  &:focus, &:active {
    border-color: $green !important;
    color: $green !important;
  }

  &:hover {
    border-color: $blue-light !important;
    color: $blue-light !important;
    background-color: transparent !important;
  }
}

@mixin ul-color($colorCirle, $colorText) {
  padding-left: 24px;

  li {
    color: $colorCirle;
    font-size: 22px;

    p {
      color: $colorText;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

@mixin steps{
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 30px 0;

  &:after {
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    height: 4px;
    background-color: $silver-grey;
    z-index: 1;
    content: '';
    display: block;
  }
}

@mixin step{
  width: 26px;
  height: 26px;
  border-radius: 13px !important;
  border: 3px solid $silver-grey;
  background: $grey;
  z-index: 2;
  padding: 0 !important;
  transition: background 0.3s ease;
  font-size: 0;
  margin: 0;

  &:hover {
    @include bg-blue-light();
  }

  &.completed {
    @include bg-green();
    cursor: pointer;
  }

  &.active {
    background-color: $astral-blue;
  }

  .activity-step-image {
    width: 20px !important;
    height: 20px !important;
  }
}
