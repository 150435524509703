* {
  box-sizing: border-box;
}

html, body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  min-width: 320px;
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
