@import './mixins';
@import './brushes';
@import 'theme';

@page {
  size: auto;
}

hr {
  border-color: $silver-grey;
}
.page-padding {
  padding: 0 25px;
  @media (min-width: 801px) {
    padding: 0 40px;
  }
  @media (min-width: 1280px) {
    padding: 0 50px;
  }
}
@mixin common-footer-down {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  app-footer {
    margin-top: auto;
  }
}
@mixin common-static-page {
  ::ng-deep .Header {
    margin-bottom: 0;
  }

  ::ng-deep .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    width: 100%;

    .static-page-title-section {
      padding: 0 25px;
      width: 100%;

      @media (min-width: 801px) {
        padding: 0 40px;
      }
      @media (min-width: 1280px) {
        padding: 0 50px;
      }

      .content_tight {
        max-width: 700px;
        @media (min-width: 740px) {
          margin: 0;
        }
      }

      h1 {
        font-size: 26px;
        font-weight: 700;
        color: $neutral-gray;
        margin: 40px 0 20px 0 !important;
      }
    }

    .static-page-content-section {
      padding: 0 25px;
      width: 100%;
      @media (min-width: 801px) {
        padding: 0 40px;
      }
      @media (min-width: 1280px) {
        padding: 0 50px;
      }
      .content {
        &_tight {
          max-width: 700px;
          @media (min-width: 740px) {
            margin: 0;
          }
        }

        .password-section {
          margin: 4rem 0;

          &__question {
            font-family: "Source Serif Pro", serif;
            font-size: 1.5rem;
            line-height: 2.875rem;
            margin: 1.5rem 0 0.5rem 0;
          }

          &__button {
            text-align: right;
          }
        }
      }
    }
  }
}

.content_download {
  @include flexer(flex, row, space-between, center);
  flex-wrap: wrap;
}

.content.content_tight h1 {
  font-size: 24px;
  font-weight: 700;
  color: $blue;
}

.ddp-block-title-bold > b,
.ddp-block-title-bold > p,
.ddp-single-question > p,
.consent-agree.bold,
.ddp-activity-content > p {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding-bottom: 5px;
  margin: 15px 0 5px 0 !important;
  display: block;
}

.title {
  font-weight: 700;

  &--grey {
    font-size: 26px;
    color: $neutral-gray;
  }

  &--green {
    padding: 20px 0 15px 0;
    font-size: 24px;
    color: $green;
  }
}

.subtitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 10px 0 10px 0;
}

.subtitle--light {
  font-weight: 300;
}

.bold {
  font-weight: bold;
}

.italic,
.infobox {
  font-size: 15px;
  font-style: italic;
  display: block;
  padding: 10px 0;
}

.align-center {
  text-align: center;
  margin: 0;
}

blockquote {
  padding: 10px 20px;
  margin: 20px 0;
  font-size: 17.5px;

  &.blockquote-full {
    @include bg-grey();
    text-align: center;
    padding: 15px;
    font-size: 16px;
  }
}

.formItem-label {
  font-size: 14px;
  font-weight: 400;
  color: $dove-grey;
}

.server-modal-box {
  max-width: none !important;
  .mat-dialog-container {
    border-radius: 0;
    color: $dove-grey;
    font-weight: bold;
    background: $panache-green;
    border-left: 10px solid $fern-green;

    .popup-message {
      .popup-message-header {
        .close {
          position: absolute;
          right: 15px;
          top: 10px;
          padding: 0;
          cursor: pointer;
          background: transparent;
          border: 0;
        }
      }
      .popup-message-content {

      }
    }
  }
}

.server-error-modal-box {
  max-width: none !important;
  .mat-dialog-container {
    border-radius: 0px;
    color: $dove-grey;
    font-weight: bold;
    background: $dawn-pink;
    border-left: 10px solid $matrix-red;

    .popup-message {
      .popup-message-header {
        .close {
          position: absolute;
          right: 15px;
          top: 10px;
          padding: 0;
          cursor: pointer;
          background: transparent;
          border: 0;
        }
      }
      .popup-message-content {

      }
    }
  }
}

.thank-you-popup {
  .popup-message-text {
    margin: 0;
  }

  .popup-message {
    position: relative;

    .mat-icon {
      right: 0 !important;
      top: 0 !important;
    }
  }
}

.c-black {
  @include c-black();
}

.c-blue {
  @include c-blue();
}

.c-bright-blue {
  @include c-bright-blue();
}

.c-purple {
  @include c-purple();
}

.c-green {
  @include c-green();
}

.c-red {
  @include c-red();
}

.c-orange {
  @include c-orange();
}

.c-pink {
  @include c-pink();
}

.c-yellow {
  @include c-yellow();
}

.ul-purple {
  @include ul-color($purple, $black);
}

.ul-orange {
  @include ul-color($orange, $black);
  padding-left: 24px;
}

.link-black {
  @include c-dark();

  &:hover {
    @include c-blue-light();
    text-decoration: none;
  }
}

.helpHint {
  font-size: 13px;
  padding-top: 15px !important;
  @include c-blue($important: '!important');
  font-style: italic !important;

  a:hover,
  a:focus,
  a:active {
    text-decoration: none !important;
  }
}

a[href^="mailto"]:hover,
a[href^="mailto"]:active {
  text-decoration: none;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $blue-light !important;
}
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: $disabled-grey !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $blue-light !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $blue-light !important;
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: $disabled-grey !important;
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: $disabled-grey !important;
}

.mat-radio-label-content {
  font-weight: normal;
}

app-activity-progress-bar .mat-progress-bar-fill::after {
  background-color: $bright-blue !important;
}

.MedicalBottomBar {
  background: $grey;
  text-align: center;
  margin: 20px 0;
  padding: 15px;
  font-size: 16px;
}

.join-us .ddp-question-prompt {
  display: flex;
  align-items: center;

  margin-bottom: 0;

  span {
    flex-shrink: 0;
  }

  position: relative;
}

@media print {
  input[type="radio"]:checked+span {
      font-weight: bold;
  }
}

[class*="NA_REASON"] {
  .mat-radio-group {
    padding-left: 1.8em;
  }
}
